<template>
    <div class="c_content_container contact">
        <!-- 상단배너 START -->
        <section class="c_section c_banner banner_contact">
            <div class="c_inner">
                <div class="img_wrapper contact">
                    <div class="icon_wrapper">
                        <img src="@/assets/images/banner/icon_contact.png" alt="아이콘" class="icon" />
                    </div>
                </div>
            </div>
            <div class="c_inner">
                <div class="page_text">
                    <h2 v-textMotion class="text_large">
                        <span class="textMotion_target">
                            <span class="text_deco">{{ $t("Contact Banner") }}</span>
                        </span>
                    </h2>
                </div>
            </div>
        </section>
        <!-- 상단배너 END -->

        <section class="c_section contact_content">
            <div class="c_inner">
                <div class="l_company_info">
                    <dl>
                        <dt>
                            <img src="@/assets/images/contact/icon_location.png" alt="아이콘" />
                        </dt>
                        <dd>{{ $t("Address") }}</dd>
                    </dl>
                    <dl>
                        <dt>
                            <img src="@/assets/images/contact/icon_email.png" alt="아이콘" />
                        </dt>
                        <dd>info@devercorp.com</dd>
                    </dl>
                    <dl>
                        <dt>
                            <img src="@/assets/images/contact/icon_tel.png" alt="아이콘" />
                        </dt>
                        <dd>02-3210-0144</dd>
                    </dl>
                </div>
                <div class="time">
                    <h2 class="text_large">
                        <img :src="time_icon" /><span>{{ time }}</span>
                    </h2>
                    <p>{{ time_text }}</p>
                </div>

                <h2 v-textMotion class="text_large">
                    <span class="textMotion_target">{{ $t("Sort Of Inquiry") }}</span>
                </h2>
                <ul v-fadein:reverse.child="{ delay: 200 }" class="contact_menu">
                    <li @click="router.push('/contact/detail?which=Estimate')">
                        <div class="text_wrapper">
                            <p>{{ $t("Estimate") }}</p>
                        </div>
                        <div class="img_wrapper">
                            <img src="@/assets/images/contact/img_esti.jpg" alt="견적" />
                        </div>
                    </li>
                    <li @click="router.push('/contact/detail?which=Partnership')">
                        <div class="text_wrapper">
                            <p>{{ $t("Partnership") }}</p>
                        </div>
                        <div class="img_wrapper">
                            <img src="@/assets/images/contact/img_partner.jpg" alt="제휴" />
                        </div>
                    </li>
                    <li @click="router.push('/contact/detail?which=Proposal')">
                        <div class="text_wrapper">
                            <p>{{ $t("Proposal") }}</p>
                        </div>
                        <div class="img_wrapper">
                            <img src="@/assets/images/contact/img_proposal.jpg" alt="제안" />
                        </div>
                    </li>
                    <li @click="router.push('/contact/detail?which=Employment')">
                        <div class="text_wrapper">
                            <p>{{ $t("Employment") }}</p>
                        </div>
                        <div class="img_wrapper">
                            <img src="@/assets/images/contact/img_incruit.jpg" alt="채용" />
                        </div>
                    </li>
                </ul>
            </div>
        </section>
    </div>
</template>
  
<script>
import { computed, onUnmounted } from "@vue/runtime-core";
import { ref } from "vue";
import { useRouter } from "vue-router";
export default {
    setup() {
        const router = useRouter();
        const time = ref("");
        const time_icon = ref("");
        const time_text = ref("");

        const isWorkTime = () => {
            const now = new Date();
            return computed(now.getHours() >= 10 && now.getHours() <= 18);
        };

        const setTimeEl = () => {
            time.value = getTimeFormat(new Date());
            (time_icon.value = isWorkTime
                ? require("@/assets/images/contact/icon_sun.png")
                : require("@/assets/images/contact/icon_moon.png")),
                (time_text.value = isWorkTime
                    ? "We are on Duty !"
                    : "We got off the Work !");
        };

        const getTimeFormat = (date) => {
            return `${("0" + date.getHours()).slice(-2)} : ${(
                "0" + date.getMinutes()
            ).slice(-2)} : ${("0" + date.getSeconds()).slice(-2)}`;
        };

        //created 시 화면에 보여질 현재 시각 설정
        setTimeEl();

        //1초마다 time 갱신
        let intervalID = setInterval(() => {
            setTimeEl();
        }, 1000);

        //Unmounted 시 타이머 해제
        onUnmounted(() => {
            clearInterval(intervalID);
        });

        return {
            router,
            time,
            time_icon,
            time_text,
            intervalID,
            isWorkTime,
            setTimeEl,
            getTimeFormat,
        };
    },
};
</script>
  
<style scoped src="@/assets/css/contact.css"></style>
  